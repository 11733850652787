<script>
import BeUserNewView from './BeUserNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'BeUserEditView',
  extends: BeUserNewView,
  methods: {
    getBeUser () {
      this.$store.dispatch('beUser/fetchOne', this.$route.params.id)
        .then(() => {
          this.beUser = this.$store.getters['beUser/detail']
          this.setUserRoles()
          this.setUserSites()
          this.loadSiteDefiningItems(false)
        })
    },
    setUserRoles () {
      this.userRoles = this.availableRoles.filter(function (availableRole) {
        return undefined !== this.beUser.roles.find(function (role) {
          return role === availableRole.id
        })
      }, this)
    },
    setUserSites () {
      this.userSites = this.allSites().filter(function (availableSite) {
        return undefined !== this.beUser.sites.find(function (site) {
          return site === availableSite.id
        })
      }, this)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('beUser.error.required_fields'))
        return
      }
      this.$store.dispatch('beUser/update', this.beUser)
        .then(() => {
          if (this.$store.getters['beUser/error'] === null) {
            this.getBeUser()
            NotifyService.setSuccessMessage(this.$t('beUser.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['beUser/error'])
          }
        })
        .catch(error => console.log(error))
    },
    resetPassword () {
      this.$store.dispatch('beUser/resetPassword', this.beUser)
        .then(() => {
          if (this.$store.getters['beUser/error'] === null) {
            this.getBeUser()
            NotifyService.setSuccessMessage(this.$t('beUser.message.password_reset'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['beUser/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getBeUser()
  }
}
</script>
